import { Link } from "gatsby"
import React from "react"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Blog = ({ slug, title, excerpt, thumbnail, content, published_at }) => {
  const TOTAL_WORDS = title?.split(" ").length + content?.split(" ").length
  const TIME_TO_READ_IN_MINUTES = Math.ceil(TOTAL_WORDS / 200)
  return (
    <div className="col mb-4">
      <div className="card border-0 shadow-sm h-100 show-on-scroll ani-in">
        <Link to={`/blog/${slug}/`} className="stretched-link">
          <img
            data-src={thumbnail?.publicURL}
            className="card-img-top lazyload"
            alt="{title}"
          />
        </Link>
        <div className="card-body">
          <div className="h4">{title}</div>
          <small className="text-muted">{published_at}</small>
          <span className="px-1">•</span>
          <small className="text-muted">
            {TIME_TO_READ_IN_MINUTES} min read
          </small>
          <p className="card-text mt-2">{excerpt}</p>
          {/* <small className="text-muted">{published_at}</small> */}
          <small>
            <Link to={`/blog/${slug}/`} className="card-link ani-link">
              Read more
            </Link>
          </small>
        </div>
      </div>
    </div>
  )
}

export default Blog
