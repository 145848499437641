import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { graphql, useStaticQuery, withPrefix } from "gatsby"
import Layout from "../components/layout"
import Blog from "../components/blog"

const query = graphql`
  {
    allStrapiPosts(sort: { order: DESC, fields: published_at }) {
      nodes {
        id
        metaTitle
        metaDescription
        title
        slug
        excerpt
        content
        author
        thumbnail {
          publicURL
        }
        published_at(formatString: "MMM DD, yy")
      }
    }
  }
`
const Blogs = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  // useScript(withPrefix("js/sticky-sidebar.min.js"))

  // useScript(withPrefix("js/sticky-sidebar.js"))

  const data = useStaticQuery(query)

  const {
    allStrapiPosts: { nodes: blogs },
  } = data

  // const handleClick = e => {
  //   e.preventDefault()
  // }

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "https://documentesign.com/blog/#webpage",
        url: "https://documentesign.com/blog/",
        name: "Blog | Document eSign",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/#website",
        },
        primaryImageOfPage: {
          "@id":
            "https://documentesign.com/static/bcc56467c53b14453fd65055583f769a/home-benefits-img.jpg#primaryimage",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Empower people through technology and innovative solutions. Stay updated with digital esignature news, technology news and much more through our offical Document eSign blog page.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/blog/"],
          },
        ],
      },
    ],
  }

  return (
    <>
      <Layout rawData={{ className: "sticky-header" }}>
        <SEO
          title="Blog | Document eSign"
          description=" Empower people through technology and innovative solutions. Stay updated with digital esignature news, technology news and much more through our offical Document eSign blog page."
          schemaMarkup={schema}
        />
        <div className="intro blog-intro">
          <div className="container">
            <h1 className="text-center text-white py-5 mt-3 mt-md-5 show-on-scroll ani-in">
              Document eSign Blog
            </h1>
          </div>
        </div>
        <div className="bg-light">
          <div className="container py-5">
            <div className="h6 text-muted text-uppercase show-on-scroll ani-in">
              Featured posts
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 mt-4">
              {blogs.map(blog => {
                return <Blog key={blog.id} {...blog} />
              })}
            </div>
          </div>
        </div>
        {/* <div className="container py-5">
          <div className="row">
            <div className="col-lg-4 d-none d-md-block show-on-scroll ani-in sticky-sidebar-container">
              <div className="sticky-sidebar">
                <div className="sticky-sidebar-inner">
                  <h6 className="text-muted text-uppercase mb-4">Topics</h6>
                  <ul className="nav flex-column nav-pills">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        href="#"
                        onClick={() => handleClick(event)}
                      >
                        All
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => handleClick(event)}
                      >
                        Community
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => handleClick(event)}
                      >
                        Design
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => handleClick(event)}
                      >
                        Developer Relations
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => handleClick(event)}
                      >
                        Engineering
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => handleClick(event)}
                      >
                        Marketplace
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => handleClick(event)}
                      >
                        News
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => handleClick(event)}
                      >
                        Product Updates
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => handleClick(event)}
                      >
                        Culture
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => handleClick(event)}
                      >
                        Trust &amp; Security
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card mb-4 border-0 show-on-scroll ani-in">
                <div className="row no-gutters">
                  <div className="col-md-4">
                    <img
                      src={withPrefix("images/poster-01.png")}
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body p-lg-0 ml-lg-3">
                      <h4>
                        How to Introduce eSignatures Into Your Business Process
                      </h4>
                      <p className="card-text">
                        Today we are in a place where we have realized the
                        importance and operations of using electronic
                        communications. For the last 15 years, the idea…
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    */}
      </Layout>
    </>
  )
}

export default Blogs
